import React, { useEffect } from 'react'
import LondonTemplate from '../../../templates/LondonTemplate'

const LondonTracks = () => {
    useEffect(() => {
        customElements.whenDefined("tds-modal").then(() => {
            document.querySelectorAll('.track-close').forEach(element => {
                element.onclick = () => { 
                    closeTrackModal(); 
                    return false; 
                }
            })
        })

        document.querySelectorAll('[data-overlay-button]').forEach(element => {
            element.addEventListener('click', (e) => {
                e.preventDefault()
                const id = e.target.href.split('#')[1]
                showOverlay(id)
            })
        })
    }, [])

    const closeTrackModal = () => {
        document.querySelectorAll('.track-modal').forEach(element => {element.open=false;})
    }

    const showOverlay = (id) => {
        //document.querySelector('.light-modal.in').classList.remove('in')
        document.querySelector(`#${id}`).classList.remove('hide')
        document.querySelector(`#${id}`).classList.add('in')  
        document.querySelector(`#${id}`).open = true;
        
        setTimeout(() => {
            document.querySelectorAll('.track-modal').forEach(oneModal => { 
                try {  
                    oneModal.shadowRoot.querySelector('.modal__close').onclick = () => {
                        closeTrackModal();
                        return false; 
                    }
                }catch(e){}
            });
        }, 500);
    }

    return (
        <LondonTemplate>
            <div className="salesforce-footer"></div>
            <div className="heroContent">
                <div className="heroSky">
                    <div className="hottieCenter">
                    <img className="herohottie" src="https://developer.salesforce.com/resources2/bootcamps2022/London/Images/hero/2023_BC_Regional-Landing-Page_Hootie.gif" alt="background" />
                    </div>
                    <img className="heroballon" src="https://developer.salesforce.com/resources2/bootcamps2022/London/Images/hero/2023_BC_Regional-Landing-Page_UK-Balloon.gif" alt="background" />
                </div>
            </div>
            <section className="container-content">
                <h1 className="h1Internal">TRACKS</h1>
                <h3>Choose your adventure.</h3>

                <div className="hilight">
                    <div className="hilight-inner">
                    <h4>
                        Immerse yourself in five days of expert-led<br />
                        learning and certification
                    </h4>
                    <p>Choose from one of nine role-based learning tracks spanning Administrator, Developer, Architect, Marketer, and Data Rockstar, and deep-dive into Salesforce, MuleSoft, and Tableau with Salesforce Certified Instructors.</p>
                    </div>
                </div>

                <div className="tracklist">
                    <div className="track">
                        <h4>Administrator Track</h4>
                        <div className="trackBg">
                            <p className="audience" ><a data-overlay-button="" href="#track-modal-1">Administrator &gt;</a></p>                  
                        </div>

                        <div className="panel-group" id="adm-accordion" role="tablist" aria-multiselectable="true">
                            <div className="panel panel-default">
                                <div className="panel-heading" role="tab" id="trk-1">
                                    <h4 className="panel-title">
                                    <a className="collapsed" role="button" data-toggle="collapse" data-parent="#adm-accordion" href="#collapse-1" aria-expanded="false" aria-controls="collapse-1">
                                        Administrator Bootcamp Track &gt;
                                    </a>
                                    </h4>
                                </div>
                                <div id="collapse-1" className="panel-collapse collapse" role="tabpanel" aria-labelledby="trk-1">
                                    <div className="panel-content">
                                    <div className="panel-row">
                                        <p className="modal-head">Administrator</p>

                                        <p>
                                            New to the world of Salesforce administration and ready to hit the ground running? If so, this track is for you. Learn the fundamentals of administering Salesforce, including how to set up new users and ensure they have access to the data they need. Discover how to import data from external systems, automate business processes, create reports and dashboards to analyze real-time data, and solve business problems by customizing the Salesforce Platform. 
                                        </p>

                                        <p className="modal-subhead">Learn how to:</p>
                                        <ul>
                                            <li>Apply best practices to become a highly-skilled administrator.</li>
                                            <li>Customize your application, including page layouts, fields, tabs, and business processes.</li>
                                            <li>Create a secure Salesforce environment.</li>
                                            <li>Generate reports and dashboards.</li>
                                        </ul>

                                        <p className="modal-subhead">Audience:</p>

                                        <ul>
                                            <li>New Salesforce Administrators</li>
                                            <li>Sales operations or power users looking to deepen their Salesforce knowledge</li>
                                            <li>Anyone looking to earn their Salesforce Administrator credential</li>
                                        </ul>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="track">
                        <h4>Developer Tracks</h4>
                        <div className="trackBg">
                            <p className="audience"><a data-overlay-button="" href="#track-modal-3">Platform Developer &gt;</a></p>
                        </div>

                        <div className="panel-group" id="dev-accordion1" role="tablist" aria-multiselectable="true">
                            <div className="panel panel-default">
                                <div className="panel-heading" role="tab" id="trk-3">
                                    <h4 className="panel-title">
                                    <a className="collapsed" role="button" data-toggle="collapse" data-parent="#dev-accordion1" href="#collapse-3" aria-expanded="false" aria-controls="collapse-3">
                                        Platform Developer Bootcamp Track &gt;
                                    </a>
                                    </h4>
                                </div>
                                <div id="collapse-3" className="panel-collapse collapse" role="tabpanel" aria-labelledby="trk-3">
                                    <div className="panel-content">
                                    <div className="panel-row">
                                        <p className="modal-head">Platform Developer</p>

                                        <p>
                                            Are you an experienced programmer who already knows how to build pro-code solutions using object-oriented programming languages such as Java, and are familiar with the Salesforce Security and Data Model and the no-code and low-code capabilities of the platform? If so, this track is for you. Learn how to create pro-code solutions using our language (Apex), and discover how to design pro-code solutions that take advantage of no-code customizations.Customize business logic using Apex classes and triggers.                              </p>

                                        <p className="modal-subhead">Learn how to:</p>
                                        <ul>
                                            <li>Customize business logic using Apex classes and triggers.</li>
                                            <li>Access data in Salesforce using SOQL.</li>
                                            <li>Manipulate records in Salesforce using DML.</li>
                                            <li>Employ best practices for designing efficient Apex solutions.</li>
                                        </ul>

                                        <p className="modal-subhead">Audience:</p>
                                        <ul>
                                            <li>Developers with knowledge of the Salesforce Platform and* *experience in object-oriented programming who want to transfer their programming skills to the Salesforce Platform</li>
                                            <li>Anyone looking to earn their Salesforce Platform Developer I credential</li>
                                        </ul>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="spacer"></div>
                        <div className="trackBg">
                            <p className="audience"><a data-overlay-button="" href="#track-modal-4">No-Code App Builder &gt;</a></p>
                        </div>

                        <div className="panel-group" id="dev-accordion2" role="tablist" aria-multiselectable="true">
                            <div className="panel panel-default">
                                <div className="panel-heading" role="tab" id="trk-4">
                                    <h4 className="panel-title">
                                    <a className="collapsed" role="button" data-toggle="collapse" data-parent="#dev-accordion2" href="#collapse-4" aria-expanded="false" aria-controls="collapse-4">
                                        No-Code App Builder Bootcamp Track &gt;
                                    </a>
                                    </h4>
                                </div>
                                <div id="collapse-4" className="panel-collapse collapse" role="tabpanel" aria-labelledby="trk-4">
                                    <div className="panel-content">
                                    <div className="panel-row">
                                        <p className="modal-head">No-Code App Builder</p>

                                        <p>
                                            Are you ready to experience the power of no-code development? If so, this track is for you. Learn about the Salesforce suite of no-code development tools and how to extend Salesforce with custom objects, customize user interfaces with Lightning pages and Lightning apps, troubleshoot data and record access, automate complex business processes, and much more.                              </p>

                                        <p className="modal-subhead">Learn how to:</p>
                                        <ul>
                                            <li>Create your data model and business logic.</li>
                                            <li>Configure and manage security settings.</li>
                                            <li>Customize the user interface.</li>
                                            <li>Automate complex business processes.</li>
                                            <li>Use Lightning to build apps and extend the capabilities of Salesforce.</li>
                                        </ul>

                                        <p className="modal-subhead">Audience:</p>
                                        <ul>
                                            <li>Developers looking to get started with app development on the Salesforce Platform</li>
                                            <li>Anyone looking to earn their Salesforce Platform App Builder credential</li>
                                        </ul>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="spacer"></div>
                        <div className="trackBg">
                            <p className="audience"><a data-overlay-button="" href="#track-modal-5">OmniStudio Developer &gt;</a></p>
                        </div>

                        <div className="panel-group" id="sys-accordion" role="tablist" aria-multiselectable="true">
                            <div className="panel panel-default">
                                <div className="panel-heading" role="tab" id="trk-6">
                                    <h4 className="panel-title">
                                    <a className="collapsed" role="button" data-toggle="collapse" data-parent="#sys-accordion" href="#collapse-7" aria-expanded="false" aria-controls="collapse-7">
                                        OmniStudio Developer Bootcamp Track &gt;
                                    </a>
                                    </h4>
                                </div>
                                <div id="collapse-7" className="panel-collapse collapse" role="tabpanel" aria-labelledby="trk-6">
                                    <div className="panel-content">
                                    <div className="panel-row">
                                        <p className="modal-head">OmniStudio Developer</p>

                                        <p>
                                            Are you an architect or developer in the Salesforce Industries ecosystem and wondering what OmniStudio is? Would you like to see how the package allows you to quickly develop and deploy engaging, customer-focused solutions? If so, this track is for you. Learn the fundamentals of building an OmniStudio solution, including how FlexCards, OmniScripts, DataRaptors, and Integration Procedures work together with their partner program, Business Rules Engine, to elevate the end-user experience. You’ll learn how to retrieve and transform internal and external data using these no-code tools to provide a Customer 360 view of data in the system.                               </p>

                                        <p className="modal-subhead">Learn how to:</p>
                                        <ul>
                                            <li>
                                                Create FlexCards and deploy them in an OmniStudio console to build that Customer 360 view.
                                            </li>
                                            <li>
                                                Create OmniScripts to guide user engagement through processes.
                                            </li>
                                            <li>
                                                Create and modify DataRaptors to get data from Salesforce, transform data, and save data back to Salesforce.
                                            </li>
                                            <li>
                                                Use Integration Procedures to execute complex operations on the server, including pulling data from internal and external sources.
                                            </li>
                                            <li>
                                                Create Lookup Tables and Expression Sets with Business Rules Engine.
                                            </li>                              </ul>

                                        <p className="modal-subhead">Audience:</p>
                                        <ul>
                                            <li>
                                                Architects and developers who are interested in learning about how Industries can provide Customer 360 solutions
                                            </li>
                                            <li>
                                                Advanced system admins who are interested in challenging themselves
                                            </li>
                                            <li>
                                                Anyone looking to earn their Salesforce OmniStudio Developer credential
                                            </li>
                                        </ul>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="track">
                        <h4>Architect Tracks</h4>
                        <div className="trackBg">
                            <p className="audience"><a data-overlay-button="" href="#track-modal-6">Integration Architect &gt;</a></p>
                        </div>

                        <div className="panel-group" id="arc-accordion" role="tablist" aria-multiselectable="true">
                            <div className="panel panel-default">
                                <div className="panel-heading" role="tab" id="trk-5">
                                    <h4 className="panel-title">
                                    <a className="collapsed" role="button" data-toggle="collapse" data-parent="#arc-accordion" href="#collapse-6" aria-expanded="false" aria-controls="collapse-6">
                                        Integration Architect Bootcamp Track &gt;
                                    </a>
                                    </h4>
                                </div>
                                <div id="collapse-6" className="panel-collapse collapse" role="tabpanel" aria-labelledby="trk-5">
                                    <div className="panel-content">
                                    <div className="panel-row">
                                        <p className="modal-head">Integration Architect</p>

                                        <p>
                                            Are you ready to transition your thinking to an Architect mindset and start building your Integration Architect expertise? If so, this track is for you. Dive into the integration domain of the Salesforce Platform. Learn about integration architecture, integration capabilities, integration patterns, integration security, and the considerations you should make when architecting a high-performing enterprise integration solution on the Salesforce Platform.                              </p>

                                        <p className="modal-subhead">Learn how to:</p>
                                        <ul>
                                            <li>Decide between integration architectures.</li>
                                            <li>Decide the right integration capabilities for a solution.</li>
                                            <li>Implement event-driven architecture.</li>
                                            <li>Apply integration security.</li>
                                        </ul>

                                        <p className="modal-subhead">Audience:</p>
                                        <ul>
                                            <li>Aspiring Salesforce Architects </li>
                                            <li>Salesforce Developers and Administrators looking to expand their overall capabilities with the Salesforce Platform</li>
                                            <li>Existing business analysts, consultants, or technical experts working to earn their Salesforce Integration Architect credential</li>
                                        </ul>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="spacer"></div>
                        <div className="trackBg">
                            <p className="audience"><a data-overlay-button="" href="#track-modal-7">Data Architect &gt;</a></p>
                        </div>

                        <div className="panel-group" id="sys-accordion" role="tablist" aria-multiselectable="true">
                            <div className="panel panel-default">
                                <div className="panel-heading" role="tab" id="trk-6">
                                    <h4 className="panel-title">
                                    <a className="collapsed" role="button" data-toggle="collapse" data-parent="#sys-accordion" href="#collapse-7" aria-expanded="false" aria-controls="collapse-7">
                                        Data Architect Bootcamp Track &gt;
                                    </a>
                                    </h4>
                                </div>
                                <div id="collapse-7" className="panel-collapse collapse" role="tabpanel" aria-labelledby="trk-6">
                                    <div className="panel-content">
                                    <div className="panel-row">
                                        <p className="modal-head">Data Architect</p>

                                        <p>
                                            Are you ready to transition your thinking to an Architect mindset and start building your Data Architect expertise? If so, this track is for you. Dive into the data domain of the Salesforce Platform. Learn about data modeling, Salesforce data management, large data management, data migration, master data management, data governance, and the considerations you should make when architecting a high-performing enterprise data management solution on the Salesforce Platform.                             
                                        </p>

                                        <p className="modal-subhead">Learn how to:</p>
                                        <ul>
                                            <li>Design scalable data models. </li>
                                            <li>Grasp Salesforce data management.</li>
                                            <li>Understand large data volume considerations.</li>
                                            <li>Make decisions about data migration, master data management, and data governance.</li>
                                        </ul>

                                        <p className="modal-subhead">Audience:</p>
                                        <ul>
                                            <li>Aspiring Salesforce Architects</li>
                                            <li>Salesforce Developers and Administrators looking to expand their overall capabilities with the Salesforce Platform</li>
                                            <li>Existing business analysts, consultants, or technical experts working to earn their Salesforce Data Architect credential </li>       
                                        </ul>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="spacer"></div>
                        <div className="trackBg">
                            <p className="audience"><a data-overlay-button="" href="#track-modal-mpa">MuleSoft Platform Architect &gt;</a></p>
                        </div>

                        <div className="panel-group" id="sys-accordion" role="tablist" aria-multiselectable="true">
                            <div className="panel panel-default">
                                <div className="panel-heading" role="tab" id="trk-mpa">
                                    <h4 className="panel-title">
                                    <a className="collapsed" role="button" data-toggle="collapse" data-parent="#sys-accordion" href="#collapse-mpa" aria-expanded="false" aria-controls="collapse-mpa">
                                        MuleSoft Platform Architect Bootcamp Track &gt;
                                    </a>
                                    </h4>
                                </div>
                                <div id="collapse-mpa" className="panel-collapse collapse" role="tabpanel" aria-labelledby="trk-mpa">
                                    <div className="panel-content">
                                    <div className="panel-row">
                                        <p className="modal-head">MuleSoft Platform Architect</p>

                                        <p>
                                            Are you an enterprise or solution architect looking to build an application network that connects applications, data, and devices within an enterprise and to external ecosystems using application programming interfaces (APIs)? If so, this track is for you. Discover how to direct the emergence of an effective application network out of individual integration solutions following API-led connectivity. 
                                        </p>
                                        <p className="modal-subhead">Learn how to:</p>
                                        <ul>
                                            <li>Break down functional requirements into business-aligned, versioned APIs with effective granularity and API data model.</li>
                                            <li>Direct creation and publication of API-related assets using RAML and Anypoint Platform components.</li>
                                            <li>Architect for non-functional requirements on the level of API invocations and API implementations using components of Anypoint Platform.</li>
                                            <li>Architect for specific requirements by augmenting API-led connectivity with event-driven architecture.</li>
                                            <li>Advise on effective use of the automation capabilities of Anypoint Platform for DevOps, CI/CD, and testing.</li>
                                            <li>Advise on the establishment and operation of a Center for Enablement (C4E). </li>
                                        </ul>

                                        <p className="modal-subhead">Audience:</p>
                                        <ul>
                                            <li>Aspiring Salesforce Architects who are focused on big-picture design decisions across an organization</li>
                                            <li>Anyone with experience with integration approaches and technologies, including:
                                                <ul>
                                                <li>Basic knowledge and experience with the components of MuleSoft's Anypoint Platform </li>
                                                <li>A clear understanding of the concepts and steps involved in developing software for the JVM</li>
                                                <li>Familiarity with basic security concepts, including certificates and encryption at rest and in transit</li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="track">
                        <h4>Tableau Track</h4>
                        <div className="trackBg">
                            <p className="audience"><a data-overlay-button="" href="#track-modal-tdr">Tableau Desktop Rockstar  &gt;</a></p>
                        </div>

                        <div className="panel-group" id="em-accordion" role="tablist" aria-multiselectable="true">
                            <div className="panel panel-default">
                                <div className="panel-heading" role="tab" id="trk-tdr">
                                    <h4 className="panel-title">
                                    <a className="collapsed" role="button" data-toggle="collapse" data-parent="#em-accordion" href="#collapse-tdr" aria-expanded="false" aria-controls="collapse-tdr">
                                        Tableau Desktop Rockstar Bootcamp Track &gt;
                                    </a>
                                    </h4>
                                </div>
                                <div id="collapse-tdr" className="panel-collapse collapse" role="tabpanel" aria-labelledby="trk-tdr">
                                    <div className="panel-content">
                                    <div className="panel-row">
                                        <p className="modal-head">Tableau Desktop Rockstar</p>

                                        <p>
                                            Are you looking to fast-track your path to becoming a Tableau data rockstar? If so, this track is for you. Learn how to use Tableau to connect to data, and then sort, filter, group, and extract that data to
                                            glean business insights and improve business performance. Ramp up quickly on foundational and advanced Tableau skills (Desktop I & II) so you can earn your Tableau Desktop Specialist or Tableau Data Analyst
                                            credential.
                                        </p>

                                        <p className="modal-subhead">Learn how to:</p>
                                        <ul>
                                            <li>Use the Tableau workspace to create a range of essential and advanced chart types for analysis.</li>
                                            <li>Combine data from multiple tables using joins, unions, and relationships.</li>
                                            <li>Create basic and more advanced calculations, including table calculations, to modify data as needed for analysis.</li>
                                            <li>Use statistical techniques to analyze data.</li>
                                            <li>Use statistical techniques to analyze data.</li>
                                            <li>Build interactive dashboards to reveal data insights, using techniques for guided analytics, interactive dashboard design, and visual best practices.</li>
                                            <li>Describe how to share and publish visualizations.</li>
                                        </ul>

                                        <p className="modal-subhead">Audience:</p>
                                        <ul>
                                            <li>Anyone looking to ramp up quickly on both foundational and more advanced Tableau skills</li>
                                            <li>Anyone looking to earn their Salesforce Tableau Desktop Specialist credential or learn a solid foundation for the Salesforce Tableau Data Analyst credential</li>
                                        </ul>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="track">
                        <h4>Marketer Track</h4>
                        <div className="trackBg">
                            <p className="audience"><a data-overlay-button="" href="#track-modal-8">Marketing Cloud Email Specialist  &gt;</a></p>
                        </div>

                        <div className="panel-group" id="em-accordion" role="tablist" aria-multiselectable="true">
                            <div className="panel panel-default">
                                <div className="panel-heading" role="tab" id="trk-7">
                                    <h4 className="panel-title">
                                    <a className="collapsed" role="button" data-toggle="collapse" data-parent="#em-accordion" href="#collapse-8" aria-expanded="false" aria-controls="collapse-8">
                                        Marketing Cloud Email Specialist Bootcamp Track &gt;
                                    </a>
                                    </h4>
                                </div>
                                <div id="collapse-8" className="panel-collapse collapse" role="tabpanel" aria-labelledby="trk-7">
                                    <div className="panel-content">
                                        <div className="panel-row">
                                            <p className="modal-head">Marketing Cloud Email Specialist</p>

                                            <p>
                                                Are you looking to get up and running on the Salesforce Marketing Cloud Email app? If so, this track is for you. Gain hands-on experience managing subscriber data, segmenting data to deliver relevant messages, and creating emails with the best practices in mind. Learn about the various automation tools available to automate campaigns and daily tasks, analyze results, and understand metrics.                              </p>

                                            <p className="modal-subhead">Learn how to:</p>
                                            <ul>
                                                <li>Utilize the different ways to store data in Marketing Cloud and build relational data models</li>
                                                <li>Create targeted, relevant messages using simple and advanced segmentation tools based on subscriber data and behavior</li>
                                                <li>Leverage different testing and sending options for your targeted messages</li>
                                                <li>Discover automated methods for sending different email communications utilizing triggers, Automation Studio, and Journey Builder.</li>
                                                <li>Create reports and analyze data for further insights.</li>
                                            </ul>

                                            <p className="modal-subhead">Audience:</p>
                                            <ul>
                                                <li>Digital email marketers who are responsible for managing their organization’s Marketing Cloud orgs </li>
                                                <li>Anyone interested in earning their Salesforce Marketing Cloud Email Specialist credential</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            <section className="container-content">
                <div className="center">
                    <img className="logo logoInternal" src="https://developer.salesforce.com/resources2/bootcamps2022/London/Images/home/2023-Bootcamp-Seal_London_Color.png" />
                </div>
            </section>

            <div className="characters schedule-bg">
                <img src="https://developer.salesforce.com/resources2/bootcamps2022/London/Images/tracks/Tracks.png" />
            </div>


            {/* MODALS */}
            <tds-modal class="track-modal hide" id="track-modal-1" open="false">
                <div slot="heading">
                    Administrator
                </div>

                <div slot="content">
                    <p>
                        New to the world of Salesforce administration and ready to hit the ground running? If so, this track is for you. Learn the fundamentals of administering Salesforce, including how to set up new users and ensure they have access to the data they need. Discover how to import data from external systems, automate business processes, create reports and dashboards to analyze real-time data, and solve business problems by customizing the Salesforce Platform.
                    </p>

                    <p className="modal-subhead">
                        Learn how to:
                    </p>

                    <ul className="ultrack">
                        <li>
                            Apply the best practices to become a highly-skilled administrator.
                        </li>
                        <li>
                            Customize your application, including page layouts, fields, tabs, and business processes.
                        </li>
                        <li>
                            Create a secure Salesforce environment.
                        </li>
                        <li>
                            Generate reports and dashboards.
                        </li>
                    </ul>

                    <p className="modal-subhead">
                        Audience:
                    </p>

                    <ul className="ultrack">
                        <li>
                            New Salesforce Administrators
                        </li>
                        <li>
                            Sales operations or power users looking to deepen their Salesforce knowledge
                        </li>
                        <li>
                            Anyone looking to earn their Salesforce Administrator credential
                        </li>
                    </ul>
                </div>

                <div slot="footer">
                    <tds-button class="track-close">Close</tds-button>
                </div>
            </tds-modal>
            
            <tds-modal class="track-modal hide" id="track-modal-3" open="false">
                <div slot="heading">
                    Platform Developer
                </div>

                <div slot="content">
                    <p>
                        Are you an experienced programmer who already knows how to build pro-code solutions using object-oriented programming languages such as Java, and are familiar with the Salesforce Security and Data Model and the no-code and low-code capabilities of the platform? If so, this track is for you. Learn how to create pro-code solutions using our language (Apex), and discover how to design pro-code solutions that take advantage of no-code customizations.
                    </p>

                    <p className="modal-subhead">
                        Learn how to:
                    </p>

                    <ul className="ultrack">
                        <li>
                            Customize business logic using Apex classes and triggers.
                        </li>
                        <li>
                            Access data in Salesforce using SOQL.
                        </li>
                        <li>
                            Manipulate records in Salesforce using DML.
                        </li>
                        <li>
                            Employ best practices for designing efficient Apex solutions.
                        </li>
                    </ul>

                    <p className="modal-subhead">
                        Audience:
                    </p>

                    <ul className="ultrack">
                        <li>
                            Developers with knowledge of the Salesforce Platform and* *experience in object-oriented programming who want to transfer their programming skills to the Salesforce Platform
                        </li>
                        <li>
                            Anyone looking to earn their Salesforce Platform Developer I credential
                        </li>
                    </ul>
                </div>

                <div slot="footer">
                    <tds-button class="track-close">Close</tds-button>
                </div>
            </tds-modal>
            
            <tds-modal class="track-modal hide" id="track-modal-4" open="false">
                <div slot="heading">
                    No-Code App Builder
                </div>

                <div slot="content">
                    <p>
                        Are you ready to experience the power of no-code development? If so, this track is for you. Learn about the Salesforce suite of no-code development tools and how to extend Salesforce with custom objects, customize user interfaces with Lightning pages and Lightning apps, troubleshoot data and record access, automate complex business processes, and much more.
                    </p>

                    <p className="modal-subhead">
                        Learn how to:
                    </p>

                    <ul className="ultrack">
                        <li>
                            Create your data model and business logic.
                        </li>
                        <li>
                            Configure and manage security settings.
                        </li>
                        <li>
                            Customize the user interface.
                        </li>
                        <li>
                            Automate complex business processes.
                        </li>
                        <li>
                            Use Lightning to build apps and extend the capabilities of Salesforce.
                        </li>
                    </ul>

                    <p className="modal-subhead">
                        Audience:
                    </p>

                    <ul className="ultrack">
                        <li>
                            Developers looking to get started with app development on the Salesforce Platform
                        </li>
                        <li>
                            Anyone looking to earn their Salesforce Platform App Builder credential
                        </li>
                    </ul>
                </div>

                <div slot="footer">
                    <tds-button class="track-close">Close</tds-button>
                </div>
            </tds-modal>
            
            <tds-modal class="track-modal hide" id="track-modal-5" open="false">
                <div slot="heading">
                    OmniStudio Developer
                </div>

                <div slot="content">
                    <p>
                        Are you an architect or developer in the Salesforce Industries ecosystem and wondering what OmniStudio is? Would you like to see how the package allows you to quickly develop and deploy engaging, customer-focused solutions? If so, this track is for you. Learn the fundamentals of building an OmniStudio solution, including how FlexCards, OmniScripts, DataRaptors, and Integration Procedures work together with their partner program, Business Rules Engine, to elevate the end-user experience. You&rsquo;ll learn how to retrieve and transform internal and external data using these no-code tools to provide a Customer 360 view of data in the system.
                    </p>

                    <p className="modal-subhead">
                        Learn how to:
                    </p>

                    <ul className="ultrack">
                        <li>
                            Create FlexCards and deploy them in an OmniStudio console to build that Customer 360 view.
                        </li>
                        <li>
                            Create OmniScripts to guide user engagement through processes.
                        </li>
                        <li>
                            Create and modify DataRaptors to get data from Salesforce, transform data, and save data back to Salesforce.
                        </li>
                        <li>
                            Use Integration Procedures to execute complex operations on the server, including pulling data from internal and external sources.
                        </li>
                        <li>
                            Create Lookup Tables and Expression Sets with Business Rules Engine.
                        </li>
                    </ul>

                    <p className="modal-subhead">
                        Audience:
                    </p>

                    <ul className="ultrack">
                        <li>
                            Architects and developers who are interested in learning about how Industries can provide Customer 360 solutions
                        </li>
                        <li>
                            Advanced system admins who are interested in challenging themselves
                        </li>
                        <li>
                            Anyone looking to earn their Salesforce OmniStudio Developer credential
                        </li>
                    </ul>
                </div>

                <div slot="footer">
                    <tds-button class="track-close">Close</tds-button>
                </div>
            </tds-modal>
            
            <tds-modal class="track-modal hide" id="track-modal-6" open="false">
                <div slot="heading">
                    Integration Architect
                </div>

                <div slot="content">
                    <p>
                        Are you ready to transition your thinking to an Architect mindset and start building your Integration Architect expertise? If so, this track is for you. Dive into the integration domain of the Salesforce Platform. Learn about integration architecture, integration capabilities, integration patterns, integration security, and the considerations you should make when architecting a high-performing enterprise integration solution on the Salesforce Platform.
                    </p>

                    <p className="modal-subhead">
                        Learn how to:
                    </p>

                    <ul className="ultrack">
                        <li>
                            Decide between integration architectures.
                        </li>
                        <li>
                            Decide the right integration capabilities for a solution.
                        </li>
                        <li>
                            Implement event-driven architecture.
                        </li>
                        <li>
                            Apply integration security.
                        </li>
                    </ul>

                    <p className="modal-subhead">
                        Audience:
                    </p>

                    <ul className="ultrack">
                        <li>
                            Aspiring Salesforce Architects
                        </li>
                        <li>
                            Salesforce Developers and Administrators looking to expand their overall capabilities with the Salesforce Platform
                        </li>
                        <li>
                            Existing business analysts, consultants, or technical experts working to earn their Salesforce Integration Architect credential
                        </li>
                    </ul>
                </div>

                <div slot="footer">
                    <tds-button class="track-close">Close</tds-button>
                </div>
            </tds-modal>
            
            <tds-modal class="track-modal hide" id="track-modal-7" open="false">
                <div slot="heading">
                    Data Architect
                </div>

                <div slot="content">
                    <p>
                        Are you ready to transition your thinking to an Architect mindset and start building your Data Architect expertise? If so, this track is for you. Dive into the data domain of the Salesforce Platform. Learn about data modeling, Salesforce data management, large data management, data migration, master data management, data governance, and the considerations you should make when architecting a high-performing enterprise data management solution on the Salesforce Platform.
                    </p>

                    <p className="modal-subhead">
                        Learn how to:
                    </p>

                    <ul className="ultrack">
                        <li>
                            Design scalable data models.
                        </li>
                        <li>
                            Grasp Salesforce data management.
                        </li>
                        <li>
                            Understand large data volume considerations.
                        </li>
                        <li>
                            Make decisions about data migration, master data management, and data governance.
                        </li>
                    </ul>

                    <p className="modal-subhead">
                        Audience:
                    </p>

                    <ul className="ultrack">
                        <li>
                            Aspiring Salesforce Architects
                        </li>
                        <li>
                            Salesforce Developers and Administrators looking to expand their overall capabilities with the Salesforce Platform
                        </li>
                        <li>
                            Existing business analysts, consultants, or technical experts working to earn their Salesforce Data Architect credential
                        </li>
                    </ul>
                </div>

                <div slot="footer">
                    <tds-button class="track-close">Close</tds-button>
                </div>
            </tds-modal>
            
            <tds-modal class="track-modal hide" id="track-modal-mpa" open="false">
                <div slot="heading">
                    MuleSoft Platform Architect
                </div>

                <div slot="content">
                    <p>
                        Are you an enterprise or solution architect looking to build an application network that connects applications, data, and devices within an enterprise and to external ecosystems using application programming interfaces (APIs)? If so, this track is for you. Discover how to direct the emergence of an effective application network out of individual integration solutions following API-led connectivity.
                    </p>

                    <p className="modal-subhead">
                        Learn how to:
                    </p>

                    <ul className="ultrack">
                        <li>
                            Break down functional requirements into business-aligned, versioned APIs with effective granularity and API data model.
                        </li>
                        <li>
                            Direct creation and publication of API-related assets using RAML and Anypoint Platform components.
                        </li>
                        <li>
                            Architect for non-functional requirements on the level of API invocations and API implementations using components of Anypoint Platform.
                        </li>
                        <li>
                            Architect for specific requirements by augmenting API-led connectivity with event-driven architecture.
                        </li>
                        <li>
                            Advise on effective use of the automation capabilities of Anypoint Platform for DevOps, CI/CD, and testing.
                        </li>
                        <li>
                            Advise on the establishment and operation of a Center for Enablement (C4E).
                        </li>
                    </ul>

                    <p className="modal-subhead">
                        Audience:
                    </p>

                    <ul className="ultrack">
                        <li>
                            Aspiring Salesforce Architects who are focused on big-picture design decisions across an organization
                        </li>
                        <li>
                            Anyone with experience with integration approaches and technologies, including:
                            <ul className="second">
                                <li>
                                    Basic knowledge and experience with the components of MuleSoft&#39;s Anypoint Platform
                                </li>
                                <li>
                                    A clear understanding of the concepts and steps involved in developing software for the JVM
                                </li>
                                <li>
                                    Familiarity with basic security concepts, including certificates and encryption at rest and in transit
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>

                <div slot="footer">
                    <tds-button class="track-close">Close</tds-button>
                </div>
            </tds-modal>
            
            <tds-modal class="track-modal hide" id="track-modal-tdr" open="false">
                <div slot="heading">
                    Tableau Desktop Rockstar
                </div>

                <div slot="content">
                    <p>
                        Are you looking to fast-track your path to becoming a Tableau data rockstar? If so, this track is for you. Learn how to use Tableau to connect to data, and then sort, filter, group, and extract that data to glean business insights and improve business performance. Ramp up quickly on foundational and advanced Tableau skills (Desktop I &amp; II) so you can earn your Tableau Desktop Specialist or Tableau Data Analyst credential.
                    </p>

                    <p className="modal-subhead">
                        Learn how to:
                    </p>

                    <ul className="ultrack">
                        <li>
                            Use the Tableau workspace to create a range of essential and advanced chart types for analysis.
                        </li>
                        <li>
                            Combine data from multiple tables using joins, unions, and relationships.
                        </li>
                        <li>
                            Create basic and more advanced calculations, including table calculations, to modify data as needed for analysis.
                        </li>
                        <li>
                            Use statistical techniques to analyze data.
                        </li>
                        <li>
                            Use statistical techniques to analyze data.
                        </li>
                        <li>
                            Build interactive dashboards to reveal data insights, using techniques for guided analytics, interactive dashboard design, and visual best practices.
                        </li>
                        <li>
                            Describe how to share and publish visualizations.
                        </li>
                    </ul>

                    <p className="modal-subhead">
                        Audience:
                    </p>

                    <ul className="ultrack">
                        <li>
                            Anyone looking to ramp up quickly on both foundational and more advanced Tableau skills
                        </li>
                        <li>
                            Anyone looking to earn their Salesforce Tableau Desktop Specialist credential or learn a solid foundation for the Salesforce Tableau Data Analyst credential
                        </li>
                    </ul>
                </div>

                <div slot="footer">
                    <tds-button class="track-close">Close</tds-button>
                </div>
            </tds-modal>
                
            <tds-modal class="track-modal hide" id="track-modal-8" open="false">
                <div slot="heading">
                    Marketer Track
                </div>

                <div slot="content">
                    <p>
                        Are you looking to get up and running on the Salesforce Marketing Cloud Email app? If so, this track is for you. Gain hands-on experience managing subscriber data, segmenting data to deliver relevant messages, and creating emails with the best practices in mind. Learn about the various automation tools available to automate campaigns and daily tasks, analyze results, and understand metrics.
                    </p>

                    <p className="modal-subhead">
                        Learn how to:
                    </p>

                    <ul className="ultrack">
                        <li>
                            Utilize the different ways to store data in Marketing Cloud and build relational data models
                        </li>
                        <li>
                            Create targeted, relevant messages using simple and advanced segmentation tools based on subscriber data and behavior
                        </li>
                        <li>
                            Leverage different testing and sending options for your targeted messages
                        </li>
                        <li>
                            Discover automated methods for sending different email communications utilizing triggers, Automation Studio, and Journey Builder.
                        </li>
                        <li>
                            Create reports and analyze data for further insights.
                        </li>
                    </ul>

                    <p className="modal-subhead">
                        Audience:
                    </p>

                    <ul className="ultrack">
                        <li>
                            Digital email marketers who are responsible for managing their organization&rsquo;s Marketing Cloud orgs
                        </li>
                        <li>
                            Anyone interested in earning their Salesforce Marketing Cloud Email Specialist credential
                        </li>
                    </ul>
                </div>

                <div slot="footer">
                    <tds-button class="track-close">Close</tds-button>
                </div>
            </tds-modal>
        </LondonTemplate>
    )
}

export default LondonTracks
